exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-imprint-jsx": () => import("./../../../src/pages/imprint.jsx" /* webpackChunkName: "component---src-pages-imprint-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-industries-asset-management-casestudy-asset-management-jsx": () => import("./../../../src/pages/industries/asset-management/casestudy-asset-management.jsx" /* webpackChunkName: "component---src-pages-industries-asset-management-casestudy-asset-management-jsx" */),
  "component---src-pages-industries-asset-management-jsx": () => import("./../../../src/pages/industries/asset-management.jsx" /* webpackChunkName: "component---src-pages-industries-asset-management-jsx" */),
  "component---src-pages-industries-banking-casestudy-banking-jsx": () => import("./../../../src/pages/industries/banking/casestudy-banking.jsx" /* webpackChunkName: "component---src-pages-industries-banking-casestudy-banking-jsx" */),
  "component---src-pages-industries-banking-jsx": () => import("./../../../src/pages/industries/banking.jsx" /* webpackChunkName: "component---src-pages-industries-banking-jsx" */),
  "component---src-pages-industries-digital-economy-casestudy-digital-economy-jsx": () => import("./../../../src/pages/industries/digital-economy/casestudy-digital-economy.jsx" /* webpackChunkName: "component---src-pages-industries-digital-economy-casestudy-digital-economy-jsx" */),
  "component---src-pages-industries-digital-economy-jsx": () => import("./../../../src/pages/industries/digital-economy.jsx" /* webpackChunkName: "component---src-pages-industries-digital-economy-jsx" */),
  "component---src-pages-industries-energy-casestudy-energy-jsx": () => import("./../../../src/pages/industries/energy/casestudy-energy.jsx" /* webpackChunkName: "component---src-pages-industries-energy-casestudy-energy-jsx" */),
  "component---src-pages-industries-energy-jsx": () => import("./../../../src/pages/industries/energy.jsx" /* webpackChunkName: "component---src-pages-industries-energy-jsx" */),
  "component---src-pages-industries-insurance-casestudy-insurance-jsx": () => import("./../../../src/pages/industries/insurance/casestudy-insurance.jsx" /* webpackChunkName: "component---src-pages-industries-insurance-casestudy-insurance-jsx" */),
  "component---src-pages-industries-insurance-jsx": () => import("./../../../src/pages/industries/insurance.jsx" /* webpackChunkName: "component---src-pages-industries-insurance-jsx" */),
  "component---src-pages-industries-jsx": () => import("./../../../src/pages/industries.jsx" /* webpackChunkName: "component---src-pages-industries-jsx" */),
  "component---src-pages-industries-mobility-casestudy-mobility-jsx": () => import("./../../../src/pages/industries/mobility/casestudy-mobility.jsx" /* webpackChunkName: "component---src-pages-industries-mobility-casestudy-mobility-jsx" */),
  "component---src-pages-industries-mobility-jsx": () => import("./../../../src/pages/industries/mobility.jsx" /* webpackChunkName: "component---src-pages-industries-mobility-jsx" */),
  "component---src-pages-industries-stock-exchanges-casestudy-stock-exchanges-jsx": () => import("./../../../src/pages/industries/stock-exchanges/casestudy-stock-exchanges.jsx" /* webpackChunkName: "component---src-pages-industries-stock-exchanges-casestudy-stock-exchanges-jsx" */),
  "component---src-pages-industries-stock-exchanges-jsx": () => import("./../../../src/pages/industries/stock-exchanges.jsx" /* webpackChunkName: "component---src-pages-industries-stock-exchanges-jsx" */),
  "component---src-pages-joinus-jobs-json-parent-file-name-js": () => import("./../../../src/pages/joinus/{JobsJson.parent__(File)__name}.js" /* webpackChunkName: "component---src-pages-joinus-jobs-json-parent-file-name-js" */),
  "component---src-pages-joinus-jsx": () => import("./../../../src/pages/joinus.jsx" /* webpackChunkName: "component---src-pages-joinus-jsx" */),
  "component---src-pages-knowhow-business-intelligence-jsx": () => import("./../../../src/pages/knowhow/business-intelligence.jsx" /* webpackChunkName: "component---src-pages-knowhow-business-intelligence-jsx" */),
  "component---src-pages-knowhow-business-technology-jsx": () => import("./../../../src/pages/knowhow/business-technology.jsx" /* webpackChunkName: "component---src-pages-knowhow-business-technology-jsx" */),
  "component---src-pages-knowhow-data-management-jsx": () => import("./../../../src/pages/knowhow/data-management.jsx" /* webpackChunkName: "component---src-pages-knowhow-data-management-jsx" */),
  "component---src-pages-knowhow-data-science-jsx": () => import("./../../../src/pages/knowhow/data-science.jsx" /* webpackChunkName: "component---src-pages-knowhow-data-science-jsx" */),
  "component---src-pages-knowhow-digital-change-jsx": () => import("./../../../src/pages/knowhow/digital-change.jsx" /* webpackChunkName: "component---src-pages-knowhow-digital-change-jsx" */),
  "component---src-pages-knowhow-jsx": () => import("./../../../src/pages/knowhow.jsx" /* webpackChunkName: "component---src-pages-knowhow-jsx" */),
  "component---src-pages-knowhow-quant-it-energy-jsx": () => import("./../../../src/pages/knowhow/quant-it-energy.jsx" /* webpackChunkName: "component---src-pages-knowhow-quant-it-energy-jsx" */),
  "component---src-pages-knowhow-quant-it-finance-jsx": () => import("./../../../src/pages/knowhow/quant-it-finance.jsx" /* webpackChunkName: "component---src-pages-knowhow-quant-it-finance-jsx" */),
  "component---src-pages-microsite-jsx": () => import("./../../../src/pages/microsite.jsx" /* webpackChunkName: "component---src-pages-microsite-jsx" */),
  "component---src-pages-people-jsx": () => import("./../../../src/pages/people.jsx" /* webpackChunkName: "component---src-pages-people-jsx" */),
  "component---src-pages-privacy-policy-jsx": () => import("./../../../src/pages/privacy-policy.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-jsx" */),
  "component---src-pages-services-casestudy-change-jsx": () => import("./../../../src/pages/services/casestudy-change.jsx" /* webpackChunkName: "component---src-pages-services-casestudy-change-jsx" */),
  "component---src-pages-services-casestudy-create-jsx": () => import("./../../../src/pages/services/casestudy-create.jsx" /* webpackChunkName: "component---src-pages-services-casestudy-create-jsx" */),
  "component---src-pages-services-casestudy-run-jsx": () => import("./../../../src/pages/services/casestudy-run.jsx" /* webpackChunkName: "component---src-pages-services-casestudy-run-jsx" */),
  "component---src-pages-services-jsx": () => import("./../../../src/pages/services.jsx" /* webpackChunkName: "component---src-pages-services-jsx" */)
}

